.footer {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgba(75, 75, 75, 0.5);
  padding: 10px;
  transition-duration: 250ms;
  display: grid;
  place-items: center;
  color: #ffffff;
  animation: fadein 1s;
  max-width: 450px;
  backdrop-filter: blur(3px);
  border-radius: 1rem;
}

.footer span {
  font-family: Iceland;
  animation: neon1 0.75s ease-in-out infinite alternate;
  font-size: 1.5rem;
}
