.App {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logo {
  padding: 8px;
  border-radius: 15px;
  transition-duration: 500ms;
  z-index: 10;
  backdrop-filter: blur(2px);
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.logo img {
  min-width: 80px;
  min-height: 40px;
}
.logo_desktop img {
  max-width: 20vw;
  max-height: 10vh;
}
.logo_mobile img {
  max-width: 30vw;
  max-height: 20vh;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.apph1 {
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Fjalla One, sans-serif;
  display: block;
  animation: opening 2.25s;
  display: grid;
  place-items: center;
  text-align: center;
}

.title {
  display: block;
  position: relative;
  text-shadow: #533d4a 1px 1px, #533d4a 2px 2px, #533d4a 3px 3px,
    #533d4a 4px 4px, #533d4a 5px 5px, #533d4a 6px 6px;
  z-index: 15;
}

#span1 {
  float: left;
  transform: translateY(0%) translateX(0%) rotate(-10deg) skew(-10deg);
}
#span2 {
  float: left;
  transform: translateY(-25%) rotate(-10deg) skew(-10deg);
}
#span3 {
  float: right;
  transform: translateY(-145%) translateX(20%) rotate(-10deg) skew(-10deg);
  z-index: 16;
}

@keyframes opening {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes neon1 {
  from {
    text-shadow: 0 0 5px #70b266, 0 0 10px #70b266, 0 0 15px #70b266,
      0 0 20px #ff5f5f, 0 0 30px #ff5f5f;
  }
  to {
    text-shadow: 0 0 2px #70b266, 0 0 5px #70b266, 0 0 7px #70b266,
      0 0 10px #ff5f5f, 0 0 15px #ff5f5f;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
