.confetti-wrapper {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 10px;
  right: 10px;
  pointer-events: none;
  overflow: hidden;
  transition-duration: 3000ms;
}

.confetti-0 {
  width: 27px;
  height: 10.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 41%;
  transform: rotate(270.75552deg);
  animation: drop-0 4.5s 0.96135s infinite;
}

@keyframes drop-0 {
  25% {
    opacity: 1.35396;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 49%;
  }
}

.confetti-1 {
  width: 43px;
  height: 17.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 73%;
  transform: rotate(114.71171deg);
  animation: drop-1 7.16667s 0.51216s infinite;
}

@keyframes drop-1 {
  25% {
    opacity: 1.13174;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 77%;
  }
}

.confetti-2 {
  width: 34px;
  height: 13.6px;
  background-color: #9b9bff;
  top: -10%;
  left: 91%;
  transform: rotate(55.71344deg);
  animation: drop-2 5.66667s 0.92289s infinite;
}

@keyframes drop-2 {
  25% {
    opacity: 1.40924;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 96%;
  }
}

.confetti-3 {
  width: 33px;
  height: 13.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 44%;
  transform: rotate(94.96522deg);
  animation: drop-3 5.5s 0.69684s infinite;
}

@keyframes drop-3 {
  25% {
    opacity: 0.60133;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 56%;
  }
}

.confetti-4 {
  width: 21px;
  height: 8.4px;
  background-color: #d13447;
  top: -10%;
  left: 71%;
  transform: rotate(2.69249deg);
  animation: drop-4 3.5s 0.39759s infinite;
}

@keyframes drop-4 {
  25% {
    opacity: 1.44989;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 86%;
  }
}

.confetti-5 {
  width: 44px;
  height: 17.6px;
  background-color: #d13447;
  top: -10%;
  left: 12%;
  transform: rotate(69.31962deg);
  animation: drop-5 7.33333s 0.65615s infinite;
}

@keyframes drop-5 {
  25% {
    opacity: 0.67371;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 13%;
  }
}

.confetti-6 {
  width: 23px;
  height: 9.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 30%;
  transform: rotate(344.42034deg);
  animation: drop-6 3.83333s 0.39512s infinite;
}

@keyframes drop-6 {
  25% {
    opacity: 0.96621;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 31%;
  }
}

.confetti-7 {
  width: 25px;
  height: 10px;
  background-color: #a1ff92;
  top: -10%;
  left: 78%;
  transform: rotate(305.48727deg);
  animation: drop-7 4.16667s 0.88667s infinite;
}

@keyframes drop-7 {
  25% {
    opacity: 0.81495;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 85%;
  }
}

.confetti-8 {
  width: 22px;
  height: 8.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 11%;
  transform: rotate(229.33586deg);
  animation: drop-8 3.66667s 0.0567s infinite;
}

@keyframes drop-8 {
  25% {
    opacity: 1.13231;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 12%;
  }
}

.confetti-9 {
  width: 22px;
  height: 8.8px;
  background-color: #d13447;
  top: -10%;
  left: 76%;
  transform: rotate(25.97105deg);
  animation: drop-9 3.66667s 0.7838s infinite;
}

@keyframes drop-9 {
  25% {
    opacity: 0.87748;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 79%;
  }
}

.confetti-10 {
  width: 18px;
  height: 7.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 31%;
  transform: rotate(300.80478deg);
  animation: drop-10 3s 0.15821s infinite;
}

@keyframes drop-10 {
  25% {
    opacity: 0.5861;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 36%;
  }
}

.confetti-11 {
  width: 25px;
  height: 10px;
  background-color: #d13447;
  top: -10%;
  left: 96%;
  transform: rotate(57.90726deg);
  animation: drop-11 4.16667s 0.9442s infinite;
}

@keyframes drop-11 {
  25% {
    opacity: 1.47256;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 107%;
  }
}

.confetti-12 {
  width: 41px;
  height: 16.4px;
  background-color: #a1ff92;
  top: -10%;
  left: 78%;
  transform: rotate(5.28395deg);
  animation: drop-12 6.83333s 0.83717s infinite;
}

@keyframes drop-12 {
  25% {
    opacity: 0.65556;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 80%;
  }
}

.confetti-13 {
  width: 48px;
  height: 19.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 34%;
  transform: rotate(252.04402deg);
  animation: drop-13 8s 0.03241s infinite;
}

@keyframes drop-13 {
  25% {
    opacity: 0.53902;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 45%;
  }
}

.confetti-14 {
  width: 18px;
  height: 7.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 90%;
  transform: rotate(34.22394deg);
  animation: drop-14 3s 0.95438s infinite;
}

@keyframes drop-14 {
  25% {
    opacity: 0.95468;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 95%;
  }
}

.confetti-15 {
  width: 40px;
  height: 16px;
  background-color: #a1ff92;
  top: -10%;
  left: 82%;
  transform: rotate(105.61551deg);
  animation: drop-15 6.66667s 0.19222s infinite;
}

@keyframes drop-15 {
  25% {
    opacity: 0.63271;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 90%;
  }
}

.confetti-16 {
  width: 18px;
  height: 7.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 37%;
  transform: rotate(341.33144deg);
  animation: drop-16 3s 0.86266s infinite;
}

@keyframes drop-16 {
  25% {
    opacity: 1.32743;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 39%;
  }
}

.confetti-17 {
  width: 30px;
  height: 12px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  transform: rotate(194.12849deg);
  animation: drop-17 5s 0.37789s infinite;
}

@keyframes drop-17 {
  25% {
    opacity: 0.71582;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 33%;
  }
}

.confetti-18 {
  width: 46px;
  height: 18.4px;
  background-color: #d13447;
  top: -10%;
  left: 32%;
  transform: rotate(284.95024deg);
  animation: drop-18 7.66667s 0.17587s infinite;
}

@keyframes drop-18 {
  25% {
    opacity: 1.0919;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 37%;
  }
}

.confetti-19 {
  width: 28px;
  height: 11.2px;
  background-color: #d13447;
  top: -10%;
  left: 94%;
  transform: rotate(82.08258deg);
  animation: drop-19 4.66667s 0.57927s infinite;
}

@keyframes drop-19 {
  25% {
    opacity: 0.96384;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 109%;
  }
}

.confetti-20 {
  width: 23px;
  height: 9.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 6%;
  transform: rotate(225.71854deg);
  animation: drop-20 3.83333s 0.61686s infinite;
}

@keyframes drop-20 {
  25% {
    opacity: 1.3174;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 10%;
  }
}

.confetti-21 {
  width: 28px;
  height: 11.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  transform: rotate(156.71226deg);
  animation: drop-21 4.66667s 0.71024s infinite;
}

@keyframes drop-21 {
  25% {
    opacity: 0.62882;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 41%;
  }
}

.confetti-22 {
  width: 38px;
  height: 15.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 96%;
  transform: rotate(89.00501deg);
  animation: drop-22 6.33333s 0.1929s infinite;
}

@keyframes drop-22 {
  25% {
    opacity: 1.0528;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 99%;
  }
}

.confetti-23 {
  width: 42px;
  height: 16.8px;
  background-color: #a1ff92;
  top: -10%;
  left: 78%;
  transform: rotate(291.30295deg);
  animation: drop-23 7s 0.76127s infinite;
}

@keyframes drop-23 {
  25% {
    opacity: 1.1487;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 86%;
  }
}

.confetti-24 {
  width: 19px;
  height: 7.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 4%;
  transform: rotate(175.31801deg);
  animation: drop-24 3.16667s 0.74506s infinite;
}

@keyframes drop-24 {
  25% {
    opacity: 1.01445;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 13%;
  }
}

.confetti-25 {
  width: 48px;
  height: 19.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 96%;
  transform: rotate(167.18651deg);
  animation: drop-25 8s 0.45643s infinite;
}

@keyframes drop-25 {
  25% {
    opacity: 1.23844;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 98%;
  }
}

.confetti-26 {
  width: 34px;
  height: 13.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 40%;
  transform: rotate(310.13424deg);
  animation: drop-26 5.66667s 0.08868s infinite;
}

@keyframes drop-26 {
  25% {
    opacity: 1.15578;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 52%;
  }
}

.confetti-27 {
  width: 32px;
  height: 12.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 89%;
  transform: rotate(291.87712deg);
  animation: drop-27 5.33333s 0.84452s infinite;
}

@keyframes drop-27 {
  25% {
    opacity: 1.01029;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 101%;
  }
}

.confetti-28 {
  width: 45px;
  height: 18px;
  background-color: #ffbf00;
  top: -10%;
  left: 60%;
  transform: rotate(68.91782deg);
  animation: drop-28 7.5s 0.19895s infinite;
}

@keyframes drop-28 {
  25% {
    opacity: 0.90868;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 70%;
  }
}

.confetti-29 {
  width: 41px;
  height: 16.4px;
  background-color: #9b9bff;
  top: -10%;
  left: 80%;
  transform: rotate(158.18644deg);
  animation: drop-29 6.83333s 0.42945s infinite;
}

@keyframes drop-29 {
  25% {
    opacity: 0.97313;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 83%;
  }
}

.confetti-30 {
  width: 39px;
  height: 15.6px;
  background-color: #9b9bff;
  top: -10%;
  left: 47%;
  transform: rotate(78.28151deg);
  animation: drop-30 6.5s 0.43118s infinite;
}

@keyframes drop-30 {
  25% {
    opacity: 1.33292;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 50%;
  }
}

.confetti-31 {
  width: 34px;
  height: 13.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 96%;
  transform: rotate(268.23185deg);
  animation: drop-31 5.66667s 0.13498s infinite;
}

@keyframes drop-31 {
  25% {
    opacity: 0.50215;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 105%;
  }
}

.confetti-32 {
  width: 48px;
  height: 19.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 3%;
  transform: rotate(162.86824deg);
  animation: drop-32 8s 0.34771s infinite;
}

@keyframes drop-32 {
  25% {
    opacity: 1.02438;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 9%;
  }
}

.confetti-33 {
  width: 22px;
  height: 8.8px;
  background-color: #a1ff92;
  top: -10%;
  left: 85%;
  transform: rotate(75.20656deg);
  animation: drop-33 3.66667s 0.05429s infinite;
}

@keyframes drop-33 {
  25% {
    opacity: 0.77854;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 88%;
  }
}

.confetti-34 {
  width: 46px;
  height: 18.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 94%;
  transform: rotate(323.03849deg);
  animation: drop-34 7.66667s 0.99821s infinite;
}

@keyframes drop-34 {
  25% {
    opacity: 1.46809;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 109%;
  }
}

.confetti-35 {
  width: 20px;
  height: 8px;
  background-color: #ffbf00;
  top: -10%;
  left: 4%;
  transform: rotate(67.50473deg);
  animation: drop-35 3.33333s 0.08s infinite;
}

@keyframes drop-35 {
  25% {
    opacity: 1.00545;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 19%;
  }
}

.confetti-36 {
  width: 33px;
  height: 13.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 73%;
  transform: rotate(213.60477deg);
  animation: drop-36 5.5s 0.5258s infinite;
}

@keyframes drop-36 {
  25% {
    opacity: 1.449;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 79%;
  }
}

.confetti-37 {
  width: 46px;
  height: 18.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 61%;
  transform: rotate(183.11042deg);
  animation: drop-37 7.66667s 0.16019s infinite;
}

@keyframes drop-37 {
  25% {
    opacity: 1.2979;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 75%;
  }
}

.confetti-38 {
  width: 35px;
  height: 14px;
  background-color: #a1ff92;
  top: -10%;
  left: 50%;
  transform: rotate(47.1984deg);
  animation: drop-38 5.83333s 0.5763s infinite;
}

@keyframes drop-38 {
  25% {
    opacity: 0.67823;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 55%;
  }
}

.confetti-39 {
  width: 33px;
  height: 13.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  transform: rotate(209.72371deg);
  animation: drop-39 5.5s 0.64499s infinite;
}

@keyframes drop-39 {
  25% {
    opacity: 0.84907;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 69%;
  }
}

.confetti-40 {
  width: 47px;
  height: 18.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 25%;
  transform: rotate(250.15634deg);
  animation: drop-40 7.83333s 0.43744s infinite;
}

@keyframes drop-40 {
  25% {
    opacity: 0.50945;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 38%;
  }
}

.confetti-41 {
  width: 48px;
  height: 19.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 66%;
  transform: rotate(122.91875deg);
  animation: drop-41 8s 0.64756s infinite;
}

@keyframes drop-41 {
  25% {
    opacity: 0.73912;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 76%;
  }
}

.confetti-42 {
  width: 19px;
  height: 7.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 4%;
  transform: rotate(276.81627deg);
  animation: drop-42 3.16667s 0.8078s infinite;
}

@keyframes drop-42 {
  25% {
    opacity: 0.59638;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 7%;
  }
}

.confetti-43 {
  width: 37px;
  height: 14.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 81%;
  transform: rotate(230.06689deg);
  animation: drop-43 6.16667s 0.99556s infinite;
}

@keyframes drop-43 {
  25% {
    opacity: 1.16365;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 94%;
  }
}

.confetti-44 {
  width: 39px;
  height: 15.6px;
  background-color: #9b9bff;
  top: -10%;
  left: 14%;
  transform: rotate(248.56624deg);
  animation: drop-44 6.5s 0.2822s infinite;
}

@keyframes drop-44 {
  25% {
    opacity: 0.54728;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 22%;
  }
}

.confetti-45 {
  width: 20px;
  height: 8px;
  background-color: #a1ff92;
  top: -10%;
  left: 98%;
  transform: rotate(183.41384deg);
  animation: drop-45 3.33333s 0.95079s infinite;
}

@keyframes drop-45 {
  25% {
    opacity: 1.24534;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 105%;
  }
}

.confetti-46 {
  width: 25px;
  height: 10px;
  background-color: #d13447;
  top: -10%;
  left: 51%;
  transform: rotate(58.61642deg);
  animation: drop-46 4.16667s 0.1368s infinite;
}

@keyframes drop-46 {
  25% {
    opacity: 1.06674;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 52%;
  }
}

.confetti-47 {
  width: 26px;
  height: 10.4px;
  background-color: #9b9bff;
  top: -10%;
  left: 2%;
  transform: rotate(73.53424deg);
  animation: drop-47 4.33333s 0.84193s infinite;
}

@keyframes drop-47 {
  25% {
    opacity: 0.6534;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 13%;
  }
}

.confetti-48 {
  width: 27px;
  height: 10.8px;
  background-color: #a1ff92;
  top: -10%;
  left: 86%;
  transform: rotate(333.10999deg);
  animation: drop-48 4.5s 0.72465s infinite;
}

@keyframes drop-48 {
  25% {
    opacity: 1.35468;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 97%;
  }
}

.confetti-49 {
  width: 32px;
  height: 12.8px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  transform: rotate(151.46533deg);
  animation: drop-49 5.33333s 0.68604s infinite;
}

@keyframes drop-49 {
  25% {
    opacity: 0.97685;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 33%;
  }
}

.confetti-50 {
  width: 34px;
  height: 13.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  transform: rotate(230.35406deg);
  animation: drop-50 5.66667s 0.91324s infinite;
}

@keyframes drop-50 {
  25% {
    opacity: 1.40634;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 73%;
  }
}

.confetti-51 {
  width: 23px;
  height: 9.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 20%;
  transform: rotate(164.2511deg);
  animation: drop-51 3.83333s 0.53222s infinite;
}

@keyframes drop-51 {
  25% {
    opacity: 1.03937;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 34%;
  }
}

.confetti-52 {
  width: 21px;
  height: 8.4px;
  background-color: #9b9bff;
  top: -10%;
  left: 2%;
  transform: rotate(107.09684deg);
  animation: drop-52 3.5s 0.94372s infinite;
}

@keyframes drop-52 {
  25% {
    opacity: 1.12304;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 7%;
  }
}

.confetti-53 {
  width: 33px;
  height: 13.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 1%;
  transform: rotate(352.31425deg);
  animation: drop-53 5.5s 0.89148s infinite;
}

@keyframes drop-53 {
  25% {
    opacity: 1.39153;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 12%;
  }
}

.confetti-54 {
  width: 34px;
  height: 13.6px;
  background-color: #a1ff92;
  top: -10%;
  left: 40%;
  transform: rotate(6.25943deg);
  animation: drop-54 5.66667s 0.30894s infinite;
}

@keyframes drop-54 {
  25% {
    opacity: 1.4604;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 45%;
  }
}

.confetti-55 {
  width: 35px;
  height: 14px;
  background-color: #ffbf00;
  top: -10%;
  left: 100%;
  transform: rotate(244.72708deg);
  animation: drop-55 5.83333s 0.01601s infinite;
}

@keyframes drop-55 {
  25% {
    opacity: 0.90899;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 114%;
  }
}

.confetti-56 {
  width: 46px;
  height: 18.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 6%;
  transform: rotate(342.56273deg);
  animation: drop-56 7.66667s 0.34388s infinite;
}

@keyframes drop-56 {
  25% {
    opacity: 0.85169;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 14%;
  }
}

.confetti-57 {
  width: 45px;
  height: 18px;
  background-color: #ffbf00;
  top: -10%;
  left: 13%;
  transform: rotate(177.67742deg);
  animation: drop-57 7.5s 0.77319s infinite;
}

@keyframes drop-57 {
  25% {
    opacity: 1.01897;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 15%;
  }
}

.confetti-58 {
  width: 48px;
  height: 19.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 79%;
  transform: rotate(310.88319deg);
  animation: drop-58 8s 0.4872s infinite;
}

@keyframes drop-58 {
  25% {
    opacity: 0.75554;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 91%;
  }
}

.confetti-59 {
  width: 45px;
  height: 18px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  transform: rotate(311.75589deg);
  animation: drop-59 7.5s 0.64552s infinite;
}

@keyframes drop-59 {
  25% {
    opacity: 0.99204;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 59%;
  }
}

.confetti-60 {
  width: 23px;
  height: 9.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 55%;
  transform: rotate(5.75472deg);
  animation: drop-60 3.83333s 0.56686s infinite;
}

@keyframes drop-60 {
  25% {
    opacity: 1.40861;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 57%;
  }
}

.confetti-61 {
  width: 28px;
  height: 11.2px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  transform: rotate(42.7484deg);
  animation: drop-61 4.66667s 0.96479s infinite;
}

@keyframes drop-61 {
  25% {
    opacity: 0.64545;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 78%;
  }
}

.confetti-62 {
  width: 17px;
  height: 6.8px;
  background-color: #d13447;
  top: -10%;
  left: 38%;
  transform: rotate(55.82711deg);
  animation: drop-62 2.83333s 0.07999s infinite;
}

@keyframes drop-62 {
  25% {
    opacity: 1.29491;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 50%;
  }
}

.confetti-63 {
  width: 30px;
  height: 12px;
  background-color: #ffbf00;
  top: -10%;
  left: 99%;
  transform: rotate(338.31987deg);
  animation: drop-63 5s 0.15028s infinite;
}

@keyframes drop-63 {
  25% {
    opacity: 0.96218;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 102%;
  }
}

.confetti-64 {
  width: 28px;
  height: 11.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 74%;
  transform: rotate(178.46527deg);
  animation: drop-64 4.66667s 0.9755s infinite;
}

@keyframes drop-64 {
  25% {
    opacity: 0.65173;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 87%;
  }
}

.confetti-65 {
  width: 21px;
  height: 8.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 41%;
  transform: rotate(294.97673deg);
  animation: drop-65 3.5s 0.64872s infinite;
}

@keyframes drop-65 {
  25% {
    opacity: 0.81919;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 44%;
  }
}

.confetti-66 {
  width: 48px;
  height: 19.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 57%;
  transform: rotate(181.90586deg);
  animation: drop-66 8s 0.37899s infinite;
}

@keyframes drop-66 {
  25% {
    opacity: 1.46226;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 69%;
  }
}

.confetti-67 {
  width: 17px;
  height: 6.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 54%;
  transform: rotate(223.89741deg);
  animation: drop-67 2.83333s 0.16895s infinite;
}

@keyframes drop-67 {
  25% {
    opacity: 1.0943;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 60%;
  }
}

.confetti-68 {
  width: 28px;
  height: 11.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 80%;
  transform: rotate(311.05031deg);
  animation: drop-68 4.66667s 0.673s infinite;
}

@keyframes drop-68 {
  25% {
    opacity: 0.9217;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 91%;
  }
}

.confetti-69 {
  width: 32px;
  height: 12.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 48%;
  transform: rotate(180.46911deg);
  animation: drop-69 5.33333s 0.01735s infinite;
}

@keyframes drop-69 {
  25% {
    opacity: 1.39348;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 59%;
  }
}

.confetti-70 {
  width: 27px;
  height: 10.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 51%;
  transform: rotate(15.9124deg);
  animation: drop-70 4.5s 0.42017s infinite;
}

@keyframes drop-70 {
  25% {
    opacity: 0.65725;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 60%;
  }
}

.confetti-71 {
  width: 23px;
  height: 9.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 94%;
  transform: rotate(303.42931deg);
  animation: drop-71 3.83333s 0.06592s infinite;
}

@keyframes drop-71 {
  25% {
    opacity: 0.65415;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 107%;
  }
}

.confetti-72 {
  width: 30px;
  height: 12px;
  background-color: #d13447;
  top: -10%;
  left: 25%;
  transform: rotate(185.44594deg);
  animation: drop-72 5s 0.18716s infinite;
}

@keyframes drop-72 {
  25% {
    opacity: 1.11808;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 31%;
  }
}

.confetti-73 {
  width: 28px;
  height: 11.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 44%;
  transform: rotate(77.21394deg);
  animation: drop-73 4.66667s 0.3264s infinite;
}

@keyframes drop-73 {
  25% {
    opacity: 1.44177;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 54%;
  }
}

.confetti-74 {
  width: 30px;
  height: 12px;
  background-color: #ffbf00;
  top: -10%;
  left: 11%;
  transform: rotate(358.56534deg);
  animation: drop-74 5s 0.19043s infinite;
}

@keyframes drop-74 {
  25% {
    opacity: 0.8349;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 25%;
  }
}

.confetti-75 {
  width: 21px;
  height: 8.4px;
  background-color: #d13447;
  top: -10%;
  left: 10%;
  transform: rotate(201.19581deg);
  animation: drop-75 3.5s 0.11139s infinite;
}

@keyframes drop-75 {
  25% {
    opacity: 1.08902;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 11%;
  }
}

.confetti-76 {
  width: 17px;
  height: 6.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 16%;
  transform: rotate(208.97341deg);
  animation: drop-76 2.83333s 0.26931s infinite;
}

@keyframes drop-76 {
  25% {
    opacity: 1.28509;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 23%;
  }
}

.confetti-77 {
  width: 38px;
  height: 15.2px;
  background-color: #a1ff92;
  top: -10%;
  left: 10%;
  transform: rotate(51.16095deg);
  animation: drop-77 6.33333s 0.30672s infinite;
}

@keyframes drop-77 {
  25% {
    opacity: 0.86554;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 15%;
  }
}

.confetti-78 {
  width: 41px;
  height: 16.4px;
  background-color: #d13447;
  top: -10%;
  left: 19%;
  transform: rotate(284.30273deg);
  animation: drop-78 6.83333s 0.06062s infinite;
}

@keyframes drop-78 {
  25% {
    opacity: 1.43194;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 31%;
  }
}

.confetti-79 {
  width: 40px;
  height: 16px;
  background-color: #a1ff92;
  top: -10%;
  left: 22%;
  transform: rotate(310.722deg);
  animation: drop-79 6.66667s 0.69779s infinite;
}

@keyframes drop-79 {
  25% {
    opacity: 0.9818;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 37%;
  }
}

.confetti-80 {
  width: 17px;
  height: 6.8px;
  background-color: #a1ff92;
  top: -10%;
  left: 92%;
  transform: rotate(136.36915deg);
  animation: drop-80 2.83333s 0.91153s infinite;
}

@keyframes drop-80 {
  25% {
    opacity: 0.60948;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 95%;
  }
}

.confetti-81 {
  width: 34px;
  height: 13.6px;
  background-color: #9b9bff;
  top: -10%;
  left: 76%;
  transform: rotate(44.83515deg);
  animation: drop-81 5.66667s 0.17758s infinite;
}

@keyframes drop-81 {
  25% {
    opacity: 0.77462;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 81%;
  }
}

.confetti-82 {
  width: 18px;
  height: 7.2px;
  background-color: #9b9bff;
  top: -10%;
  left: 95%;
  transform: rotate(331.59031deg);
  animation: drop-82 3s 0.92224s infinite;
}

@keyframes drop-82 {
  25% {
    opacity: 0.98078;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 105%;
  }
}

.confetti-83 {
  width: 45px;
  height: 18px;
  background-color: #ffbf00;
  top: -10%;
  left: 7%;
  transform: rotate(115.17558deg);
  animation: drop-83 7.5s 0.76099s infinite;
}

@keyframes drop-83 {
  25% {
    opacity: 0.565;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 11%;
  }
}

.confetti-84 {
  width: 32px;
  height: 12.8px;
  background-color: #9b9bff;
  top: -10%;
  left: 91%;
  transform: rotate(225.96013deg);
  animation: drop-84 5.33333s 0.41013s infinite;
}

@keyframes drop-84 {
  25% {
    opacity: 1.21418;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 104%;
  }
}

.confetti-85 {
  width: 30px;
  height: 12px;
  background-color: #a1ff92;
  top: -10%;
  left: 78%;
  transform: rotate(261.04583deg);
  animation: drop-85 5s 0.35137s infinite;
}

@keyframes drop-85 {
  25% {
    opacity: 0.99281;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 89%;
  }
}

.confetti-86 {
  width: 19px;
  height: 7.6px;
  background-color: #d13447;
  top: -10%;
  left: 61%;
  transform: rotate(45.05489deg);
  animation: drop-86 3.16667s 0.41882s infinite;
}

@keyframes drop-86 {
  25% {
    opacity: 1.32857;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 68%;
  }
}

.confetti-87 {
  width: 26px;
  height: 10.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 60%;
  transform: rotate(124.97792deg);
  animation: drop-87 4.33333s 0.13016s infinite;
}

@keyframes drop-87 {
  25% {
    opacity: 0.69851;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 71%;
  }
}

.confetti-88 {
  width: 20px;
  height: 8px;
  background-color: #ffbf00;
  top: -10%;
  left: 34%;
  transform: rotate(309.69222deg);
  animation: drop-88 3.33333s 0.70386s infinite;
}

@keyframes drop-88 {
  25% {
    opacity: 1.43736;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 49%;
  }
}

.confetti-89 {
  width: 44px;
  height: 17.6px;
  background-color: #ffbf00;
  top: -10%;
  left: 56%;
  transform: rotate(343.6212deg);
  animation: drop-89 7.33333s 0.07075s infinite;
}

@keyframes drop-89 {
  25% {
    opacity: 0.89171;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 67%;
  }
}

.confetti-90 {
  width: 20px;
  height: 8px;
  background-color: #d13447;
  top: -10%;
  left: 3%;
  transform: rotate(143.95deg);
  animation: drop-90 3.33333s 0.93051s infinite;
}

@keyframes drop-90 {
  25% {
    opacity: 1.39969;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 12%;
  }
}

.confetti-91 {
  width: 33px;
  height: 13.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 55%;
  transform: rotate(162.00634deg);
  animation: drop-91 5.5s 0.82733s infinite;
}

@keyframes drop-91 {
  25% {
    opacity: 0.96352;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 63%;
  }
}

.confetti-92 {
  width: 20px;
  height: 8px;
  background-color: #d13447;
  top: -10%;
  left: 88%;
  transform: rotate(160.74811deg);
  animation: drop-92 3.33333s 0.29292s infinite;
}

@keyframes drop-92 {
  25% {
    opacity: 1.35047;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 89%;
  }
}

.confetti-93 {
  width: 27px;
  height: 10.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 91%;
  transform: rotate(300.16846deg);
  animation: drop-93 4.5s 0.92571s infinite;
}

@keyframes drop-93 {
  25% {
    opacity: 1.18237;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 92%;
  }
}

.confetti-94 {
  width: 34px;
  height: 13.6px;
  background-color: #9b9bff;
  top: -10%;
  left: 41%;
  transform: rotate(99.51675deg);
  animation: drop-94 5.66667s 0.00497s infinite;
}

@keyframes drop-94 {
  25% {
    opacity: 1.21691;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 42%;
  }
}

.confetti-95 {
  width: 22px;
  height: 8.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 70%;
  transform: rotate(124.72054deg);
  animation: drop-95 3.66667s 0.99629s infinite;
}

@keyframes drop-95 {
  25% {
    opacity: 1.01214;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 81%;
  }
}

.confetti-96 {
  width: 37px;
  height: 14.8px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  transform: rotate(68.36371deg);
  animation: drop-96 6.16667s 0.95009s infinite;
}

@keyframes drop-96 {
  25% {
    opacity: 0.5902;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 15%;
  }
}

.confetti-97 {
  width: 45px;
  height: 18px;
  background-color: #a1ff92;
  top: -10%;
  left: 26%;
  transform: rotate(179.00169deg);
  animation: drop-97 7.5s 0.48562s infinite;
}

@keyframes drop-97 {
  25% {
    opacity: 1.32088;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 28%;
  }
}

.confetti-98 {
  width: 37px;
  height: 14.8px;
  background-color: #d13447;
  top: -10%;
  left: 32%;
  transform: rotate(341.91952deg);
  animation: drop-98 6.16667s 0.3491s infinite;
}

@keyframes drop-98 {
  25% {
    opacity: 1.15555;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 36%;
  }
}

.confetti-99 {
  width: 21px;
  height: 8.4px;
  background-color: #d13447;
  top: -10%;
  left: 30%;
  transform: rotate(279.74212deg);
  animation: drop-99 3.5s 0.27496s infinite;
}

@keyframes drop-99 {
  25% {
    opacity: 0.96396;
  }

  70% {
    opacity: 0;
  }

  100% {
    top: 140%;
    left: 34%;
  }
}

.confetti-100 {
  width: 21px;
  height: 8.4px;
  background-color: #a1ff92;
  top: -10%;
  left: 1%;
  transform: rotate(311.95343deg);
  animation: drop-100 3.5s 0.55614s infinite;
}

@keyframes drop-100 {
  25% {
    opacity: 0.8274;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    top: 140%;
    left: 8%;
  }
}

/*
*
* SCSS code
$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 100 {
  $w: 16+random(32);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(($w/8)+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    90% {
      opacity: 0;
    }
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}
*
*/
